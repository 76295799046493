//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    TaskList: () => import("./task-list"),
  },
  props: {
    selTask: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    selMem: {
      type: Array,
      default: null,
    },
  },
  data() {
    var checkTask = (rule, value, callback) => {
      if (!this.formData.taskId || !this.formData.taskName) {
        return callback(new Error("请选择任务"));
      } else {
        callback();
      }
    };
    return {
      teamOptions: [],
      formData: {
        Describe: "", //消息内容
        sdate: null,
        edate: null,
        taskName: null,
        taskId: null,
        isMorrowS: false,
        isMorrowE: false,
      },

      users: [],
      rules: {
        sdate: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
        edate: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
        Describe: [
          { required: true, message: "请输入任务描述", trigger: "blur" },
        ],
        task: [{ validator: checkTask, trigger: "blur", required: true }],
      },
    };
  },
  methods: {
    closed() {
      this.formData.Describe = ""; //消息内容
      this.formData.sdate = null;
      this.formData.edate = null;
      this.formData.taskName = null;
      this.formData.taskId = null;
    },
    /**
     * 获取任务
     */
    getTask(val) {
      this.formData.taskName = val.Title;
      this.formData.taskId = val.Id;
    },
    opened() {
      this.$nextTick(() => {
        this.formData.sdate = this.selTask.CreateTime.timeFormat(
          "yyyy-MM-dd HH:mm:ss"
        );
        if (this.selTask.UpdateTime) {
          this.formData.edate = this.selTask.UpdateTime.timeFormat(
            "yyyy-MM-dd HH:mm:ss"
          );
        } else {
          this.formData.edate = new Date().timeFormat("yyyy-MM-dd HH:mm:ss");
        }
      });
    },
    /**
     * 选择任务列表
     */
    handleSelTask() {
      this.$modal.show("tasklist");
    },
    handleConfirm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.$refs.xmodal.loadBtn(true);
          const data = {
            teamId: this.teamValue,
            taskId: this.formData.taskId,
            Describe: this.formData.Describe,
            sdate: this.formData.sdate,
            edate: this.formData.edate,
            IsMorrow_s: this.formData.isMorrowS,
            IsMorrow_e: this.formData.isMorrowE,
          };
          this.$http.post("/Task/RepairTaskHistory.ashx", data).then((resp) => {
            if (resp.res == 0) {
              this.$refs.xmodal.loadBtn(false);
              this.$message({
                showClose: true,
                message: "任务补交成功！",
                type: "success",
              });
              this.$modal.hide("makeup");
              //刷新任务卡片
              this.$emit("loadData");
            } else {
              this.$refs.xmodal.loadBtn(false);
            }
          });
        } else {
          return;
        }
      });
    },
  },
};
